@import "@fortawesome/fontawesome-free/css/all.min.css";

@font-face {
    font-family: 'WickedRegular';
    src: url('./assets/fonts/Wicked/WickedBold.otf');
    font-weight: normal;
}
@font-face {
    font-family: 'WickedDemo';
    src: url('./assets/fonts/Wicked/WickedDemo.woff');
    font-weight: normal;
}

.App {
  text-align: center;
}

.App-logo {
    height: 20vmin;
    width: 20vmin;
    pointer-events: none;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    margin-bottom: 10px;
}
.operator-logo {
    height: 20vmin;
    width: 20vmin;
    pointer-events: none;
    margin: 0 auto;
    margin-bottom: 10px;
}
.operator-logo img {
    height: 100%;
    width: 100%;
}
.FirstLoad-logo {
    height: 20vmin;
    width: 20vmin;
    pointer-events: none;
    background-image: url("./assets/img/loadIcon.png");
    filter: invert(1);
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    margin-bottom: 10px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
    .FirstLoad-logo {
        animation: App-logo-spin infinite 5s linear;
    }
    .operator-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
