.Lobby-container {
    font-size: 14px;
}
.Lobby-container header {
    background: linear-gradient(to right, #20002c, #7842ff);
}

.Lobby-container .quicktip-lobby-card {
    background: #374358;
}
.Lobby-container .quicktip-lobby-card .card-header {
    color: #fff;
}

.Lobby-container .quicktip-lobby-card .list-item-bg-light {
    background: #272f3c !important;
}
.Lobby-container .quicktip-lobby-card .list-item-bg-dark {
    background: #202632 !important;
}

.Lobby-container .quicktip-lobby-card .expires {
    align-items: center;
}

.Lobby-container .quicktip-lobby-card .expires-time {
    min-width: 75px;
    text-align: center;
}
.Lobby-container .quicktip-lobby-card .quicktip-title {
    text-align: center;
}
.Lobby-container .smartbet-lobby-card {
    background: #374358;
    color: #fff;
}
.Lobby-container .smartbet-lobby-card .smartbet-card-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Lobby-container .smartbet-lobby-card .smartbet-card-link img {
    border-radius: 10px;
}

.Lobby-container .smartbet-lobby-card ul {
    list-style: none;
    padding: 0 10px;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-evenly;
}
.Lobby-container .smartbet-lobby-card ul li {
    margin-right: 10px;
}
.Lobby-container .superbet-lobby-card {
    background: #374358;
}
.Lobby-container .quicktip-lobby-card .card-header {
    color: #fff;
}
.Lobby-container .superbet-lobby-card ul {
    padding: 0;
}

.Lobby-container .superbet-lobby-card .list-item-bg-light {
    background: #272f3c !important;
}
.Lobby-container .superbet-lobby-card .list-item-bg-dark {
    background: #202632 !important;
}
.Lobby-container .superbet-lobby-card .expires-time {
    min-width: 75px;
    text-align: center;
}

.Lobby-container .superbet-lobby-card .team {
    display: flex;
}
.Lobby-container .superbet-lobby-card .team .team-name{
    margin-left: 5px;
}
.lite {
    background: #f7f7f7 !important;
}
.lite header {
    background: #fff !important;
    border-bottom: .5px solid #3743586b;
}
.lite .quicktip-lobby-card, .smartbet-lobby-card, .superbet-lobby-card {
    background: #fff !important;
    color: #000 !important;
}

.lite .quicktip-lobby-card, .smartbet-lobby-card, .superbet-lobby-card {}

.lite .superbet-lobby-card .list-item-bg-light {
    background: #e5e5e5 !important;
}
.lite .superbet-lobby-card .list-item-bg-dark {
    background: white !important;
}
.lite .quicktip-lobby-card .list-item-bg-light {
    background: #e5e5e5 !important;
    color: #000 !important;
}
.lite .quicktip-lobby-card .list-item-bg-dark {
    background: white !important;
    color: #000 !important;
}


.text-green {
    color: #13AA9E !important;
}